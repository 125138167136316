@import url(ie8-grid-support.css);
.lt-ie9 .show-for-medium-down,
.lt-ie9 .show-for-medium,
.lt-ie9 .show-for-small,
.lt-ie9 .show-on-android {
  display: none !important; }
.lt-ie9 .show-for-large-up,
.lt-ie9 .hide-for-medium-down,
.lt-ie9 .hide-on-android {
  display: block !important; }

.main-header .logo__desktop {
    display: block;
}
.main-header div.logo a, .main-header h1 a {
    display: block;
}
.main-header div.logo, .main-header h1{
    margin:12px 0!important;
}
.main-header .menu .main-nav ul li.nav-item>a{
	font-size:13px;
}
.main-header .menu .main-nav ul li.informacio {
min-width: 10%;
}
.main-header .menu .main-nav ul li.universitat {
    min-width: 10%;
}
.menu .main-nav ul li.campus {
    min-width: 10%;
}
.main-header .menu .main-nav ul a {
    padding: .5em 0;
}
.js .main-header .menu.load {
    overflow: visible!important;
}
.main-header .menu {
    overflow: visible!important;
}
.main-header .menu .main-nav .nav-menu .nav-item .sub-nav .intro {
    display: none;
}
.main-nav__lang.open,.main-nav__dreceres.open{
	margin: 0;
    display: block!important;
    position: absolute;
    z-index: 100;
    min-width: 200px;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    background: #FFF;
    border: 1px solid #C1C1C1;
    border: 1px solid rgba(0,0,0,.2);
    list-style: none;
    text-align: left;
    padding: 0;
}
.main-header .menu .main-nav ul li.nav-item>a:after{
	content: none!important;
}
.main-header .eines{
	display:none;
}
.main-header #main-nav{
	height: 46px;
}
.home .destaquem-vincles .text h3, .subportada .destaquem-vincles .text h3,.home .agenda h3{
	font-size:15px!important;
}
.home .destaquem-vincles figure, .subportada .destaquem-vincles figure{
	max-width: 60px!important;
}
.home .destaquem-vincles .text{
	padding-left:75px;
}
.home .estudia .estudia-item, .subportada .estudia .estudia-item{
	min-height: 250px;
}
.home .estudia .estudia-item h3, .subportada .estudia .estudia-item h3{
font-size:34px!important;
max-width: 150px;
}

.section-nav,.main-titol h1,.alerta div,#breadcrumbs{
	max-width:1000px!important;
}
.contingut #tabs li, .contingut .pestanyes li{
	margin-left:0!important;
}
.contingut #tabs li, .contingut .pestanyes li{
	font-size:15px!important;
}
.contingut ul.botons-accio li a{
	padding-top:25px;
	padding-bottom:25px;
}
.contingut ul.botons-accio li a:before{
	top:40px;
}
.ensenyaments .contingut .estudis .filtrar, .contingut .localitzacions .filtrar{
	display: none;
}
.ensenyaments .contingut .intro{
	max-height: 255px;
}
.localitzacions ul{
	margin-top:2em;
}
.main-titol.b{
	background-position: center top;
}
.subportada .caixes-colors li h2{
	height: 190px;
	overflow: hidden;
}
.subportada .caixes-colors a{
	padding:0 20px;
}
.subportada .caixes-colors a span{
	left:0;
	top:15%;
	font-size:22px!important;
}
.contingut .collapsible button:focus {
    background-color: transparent!important;
}
